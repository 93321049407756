<template>

  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions mt-5">
      <h3>Обменник - Архив - транзакции </h3>
    </div>

    <DatePeriodPicker caption="Период выборки" :from="from" :to="to" v-on:changed="onPerodChanged"></DatePeriodPicker>


    <div class="mt-5">
      <p><strong>Фильтр по столбцам</strong> - работает по вхождению подстроки. В ID - только цифры.</p>
      <v-form>
        <v-row>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >

            <v-text-field
                v-model="id"
                label="ID"
                outlined
                clearable
                type="number"
                max="2147483647"
                oninput="validity.valid||(value='');"

            ></v-text-field>

          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >

            <v-text-field
                v-model="card"
                label="Карта"
                outlined
                clearable

            ></v-text-field>

          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >
            <CurrencySelect v-on:changed="currencychanged"></CurrencySelect>
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >

            <BankCombobox3   v-bind:bank.sync="bank"    ></BankCombobox3>
          </v-col>






          <v-col
              cols="12"
              sm="6"
              md="2"
          >
            <ExchangeUserComboboxMerchant  v-bind:user.sync="user" ></ExchangeUserComboboxMerchant>

          </v-col>


          <v-col
              cols="12"
              sm="6"
              md="2"
          >

            <v-radio-group row dense v-model="radios">
              <v-radio label="Только успешные" value="1"></v-radio>
              <v-radio label="Все" value="2"></v-radio>
              <v-radio label="Только отмены" value="3"></v-radio>
            </v-radio-group>
          </v-col>

        </v-row>


      </v-form>
    </div>

    <div class="text-center pt-2">


      <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="15"
      ></v-pagination>

      <div class="d-flex justify-center ma-2">
        <span class="mr-1 mt-1"> Показывать на странице: </span>
        <v-select
            style="max-width: 80px"
            dense
            v-model="itemsPerPage"
            :items="Pages"
            return-object
        ></v-select>
        <span class="ml-1  mt-1"> строк из {{ totalRows }}</span>
      </div>

    </div>


    <v-row>

      <v-col cols="6">
        <v-card class="pa-2 ma-0" height="130">
          Итого суммы по текущей странице:<br>
          {{ sumRub.toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'}) }}<br>
          {{ sumUsd.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'}) }}<br>
          {{ sumUsdt.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'}) }}USDT <br>
          <span class="font-weight-thin">Суммируется только completed по текущей странице.</span>
        </v-card>
      </v-col>

      <v-col cols="6">

        <v-card class="pa-2  ma-0" height="130">
          <span> Скачать CSV по текущей выборке:</span><br>
          <v-btn class="ma-1" v-on:click="csv(false)">В кодировке UTF-8</v-btn>
          <v-btn class="ma-1" v-on:click="csv(true)">В кодировке Win-1251</v-btn>
        </v-card>

      </v-col>
    </v-row>


    <div>
      <v-data-table
          sort-by="id"
          sort-desc

          item-key="id"

          show-expand
          expanded.sync="expanded"

          hide-default-footer
          disable-pagination

          @item-expanded="expevent"

          dense
          multi-sort
          :items="transactions"
          :headers="headers"
          class="elevation-1 mt-3"
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[  50,  100,  200,  -1]  }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">
          <v-chip v-if="item.status==='created'" color="primary"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='canceled'" color="gray"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='completed'" color="green"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='offer'" color="orange"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='accepted'" color="green"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='proof'" color="blue"> {{ item.status }}</v-chip>
          <v-chip v-else color="brown"> {{ item.status }}</v-chip>
        </template>

        <template v-slot:item.updatedAt="{ item }"> {{
            new Date(Date.parse(item.updatedAt)).toLocaleString()
          }}
        </template>
        <template v-slot:item.amountUsd="{ item }"> {{ calcUsdAmount(item).toLocaleString('ru-RU') }}</template>
        <template v-slot:item.amountRub="{ item }"> {{ calcRubAmount(item).toLocaleString('ru-RU') }}</template>

        <template v-slot:item.fee="{ item }"> {{ item.offer.fee }}</template>
        <template v-slot:item.rate="{ item }"> {{ item.offer.rate }}</template>
        <template v-slot:item.usdt="{ item }"> {{
            usdt(item).toLocaleString('ru-RU', {
              style: 'currency',
              currency: 'USD'
            }) + "USDT"
          }}
        </template>

        <template v-slot:item.currency="{ item }"> {{ item.bank ? get$ByCountry(item.bank.country) : '--' }}</template>
        <template v-slot:item.bank="{ item }"> {{ item.bank ? item.bank.name : '--' }}</template>

        <template v-slot:item.card="{ item }"> {{ item.card ? item.card.number : '--' }}</template>
        <template v-slot:item.cardowner="{ item }"> {{ item.card ? item.card.owner : '--' }}</template>

        <template v-slot:item.user="{ item }"> {{ item.user ? item.user.username : '--' }}</template>

        <template v-slot:item.actions="{ item }">

          <v-btn class="orange" v-if="item.status==='canceled'  " dense text
                 @click="patch(item)">в Completed
          </v-btn>


          <v-btn class="primary" dense text
                 @click="repeat(item)">Повтор колбэк
          </v-btn>

          <v-btn class="brown" dense text
                 @click="recovery(item)">Восстановить
          </v-btn>


        </template>


        <template v-slot:expanded-item="{ headers, item }">

          <td :colspan="headers.length-2">

            <div v-if="cached[item.id]">
              <span>{{ `Курс: ${cached[item.id].course }` }}</span><br>
              <span>{{ `ID операции: ${cached[item.id].id }` }}</span><br>
            </div>




            <span>{{ `ИД чата: ${item.card ? item.card.chatId : "--"}` }}</span><br>
            <span>{{ `Время обработки: ${formatPeriod(item.createdAt, item.updatedAt)}` }}</span><br>

            <span>История:</span><br>

            <div v-if="cached2[item.id]">

              <div v-for="row in cached2[item.id]" :key="row">
                <span>{{ ` ${row}` }}</span><br>

              </div>


            </div>
          </td>


        </template>


      </v-data-table>
    </div>


    <div class="text-center pt-2">


      <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="15"
      ></v-pagination>


      <div class="d-flex justify-center ma-2">
        <span class="mr-1 mt-1"> Показывать на странице: </span>
        <v-select
            style="max-width: 80px"
            dense
            v-model="itemsPerPage"
            :items="Pages"
            return-object
        ></v-select>
        <span class="ml-1  mt-1"> строк из {{ totalRows }}</span>
      </div>

    </div>


  </div>


</template>

<script>

import DatePeriodPicker from "../components/controls/DatePeriodPicker.vue";
import gql from "graphql-tag";
import {getHeaders} from "../store";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/transaction"
import {fetcher} from "../store";
import Snackbar from "../components/controls/Snackbar.vue";
//import BankCombobox from "../components/controls/BankCombobox.vue";
//import UserCombobox from "../components/controls/UserCombobox.vue";
import CurrencySelect from "../components/controls/CurrencySelect.vue";
import {apolloProvider} from "../store/appoloProvider";
import BankCombobox3 from "../components/controls/BankCombobox3.vue";
import ExchangeUserComboboxMerchant from "../components/controls/ExchangeUserComboboxMerchant.vue";

//const exchange_user = process.env.VUE_APP_EXCHANGE_USER




export default {
  name: 'ExchangeTransactionsArchiveFull',

  components: {ExchangeUserComboboxMerchant, BankCombobox3,  DatePeriodPicker, Snackbar,  CurrencySelect},

  apollo: {

    transactions: {
      query: gql`
      query s($from: timestamptz! = "2021-01-01", $to: timestamptz! = "2025-01-01", $exp: transactions_bool_exp! = {}, $lim: Int = 50, $ofs: Int = 0) {
  transactions(where: {
  _and: [{updatedAt: {_gte: $from}},
         {updatedAt: {_lte: $to}},
         $exp]

  }, order_by: {id: desc}, limit: $lim, offset: $ofs) {
    id
    updatedAt
    uid
    merchantId
    status
    offer
    createdAt

    amount
    currency

    card {
      owner
      number
      chatId
    }
    bank {
      name
      country
    }
    user {
      username
    }
  }


    transactions_aggregate(where: {_and: [{updatedAt: {_gte: $from}}, {updatedAt: {_lte: $to}}, $exp]}, order_by: {id: desc}) {
    aggregate {
      count
    }
  }

}

`,


      variables() {

        let id_exp = this.id ? {id: {_eq: this.id}} : {};
        let status_exp = {}
        switch (this.radios) {
          case "1":
            status_exp = {status: {_eq: "completed"}}
            break
          case "3":
            status_exp = {status: {_eq: "canceled"}}
            break

          default:
            status_exp = {}
        }

        let bank_exp = this.bank ? {bank: {name: {_ilike: `%${this.bank.name}%`}}} : {}
        let user_exp = this.user ? {user: {username: {_eq: `${this.user.username}`}}} : {}
        let card_exp = this.card ? {card: {number: {_ilike: `%${this.card}%`}}} : {}
        let country_exp = this.country ? {bank: {country: {_eq: `${this.country}`}}} : {}

        let exp = {_and: [id_exp, status_exp, bank_exp, user_exp, card_exp, country_exp]}


        return {
          lim: this.itemsPerPage,
          ofs: (this.page - 1) * this.itemsPerPage,
          from: this.from,
          to: this.to,
          exp: exp,
        }
      },

      result({data}) {
        this.transactions = data.transactions;
        this.computeSumm();
        this.totalRows = data.transactions_aggregate.aggregate.count;
      },

    }
  },

  data: () => ({

    bankname: null,

    user:null,

    expanded: [],

    cached: [],
    cached2: [],

    Pages: [30, 50, 100, 1000, 10000],

    showCanceled: false,
    radios: "1",

    page: 1,

    itemsPerPage: 30,

    search: "",
    id: "",
    card: "",
    bank: "",
    //user: "",

    country: "",

    sumRub: 0,
    sumUsd: 0,
    sumUsdt: 0,

    filter: {},

    from: "2024-01-01",
    to: "2024-12-31",

    filterByDatePeriod: false,
    transactions: [],

    totalRows: 0,

    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Статус', value: 'status'},
      {text: 'Дата обн.', value: 'updatedAt'},
     // {text: 'Начислено USD', value: 'amountUsd'},
      {text: 'Сумма', value: 'amountRub'},
      {text: 'Валюта', value: 'currency'},

      {text: 'Банк', value: 'bank'},
     // {text: 'Начислено USDT', value: 'usdt'},
      {text: 'Карта', value: 'card'},
      {text: 'Владелец', value: 'cardowner'},
      {text: 'Мерчант', value: 'user'},
      {text: 'UID', value: 'uid'},
      {text: 'Действия', value: 'actions'},
    ],
  }),

  /*
  [ID заявки] - ID пополнения  из БД
  [Мерчант] - Логин (ну или ID) мерчанта uid
  [Создана] - дата создания
  [Пополнение] - сумма пополнения баланса
  [Сумма] - сумма перевода (для рублевых терминалов Пополнение=Сумме)
  [Банк] - пополнение через какой банк
  [Карта] - карта, на которую пришел платеж. В идеале в рамках 1 столбца - ниже - вывести коммент к этой карте из БД
  [Чек] - файл чека
pa   */

  computed: {

    username() {
      return this.$store.state.user.username
    },

    pageCount() {
      return this.totalRows ? Math.trunc(this.totalRows / this.itemsPerPage) + 1 : 0
    },

    special4watch() {
      return `${this.id}|${this.card}|${this.bank}|${this.user}|${this.country}|${new Date(Date.parse(this.from)).toLocaleString()}|${new Date(Date.parse(this.to)).toLocaleString()}|${this.radios} |${this.itemsPerPage} `;
    },
  },

  watch: {
    special4watch: function () {
      this.page = 1
    },
  },


  methods: {


    expevent2(param) {

      const i = param.item
      const e = param.value

      //console.log(e, i)

      if (!e)
        return

      if (!this.cached.includes(e)) {

        const GET_TH = gql`query s($id: Int!) {
  transactionHistories(where: {transId: {_eq: $id}}, order_by: {id: desc}) {
    transId
    createdAt
    id
    status
    updatedAt
    value
  }
}`

        apolloProvider.defaultClient.query({query: GET_TH, variables: {id: i.id}}).then(response => {

          let data = response.data.transactionHistories

          let datas = []

          data.forEach(row => datas.push(this.generateTransactionText(row)))


          this.$set(this.cached2, i.id, datas)


          // console.log(data)
        });

        console.log("data")

      }

    },

    expevent(param) {
      this.expevent0(param)
      this.expevent2(param)
    },



    expevent0(param) {

      const i = param.item
      const e = param.value

      //console.log(e, i)

      if (!e)
        return

      if (!this.cached.includes(e)) {

        const GET_TH = gql`
        query s($id: Int!) {
  history(where: {operation_id: {_eq: $id}},order_by: {operation_id: asc}) {
    operation_id
    id
    course
    transactions {
      id
    }
    deposits {
      id
    }
    conversions {
      id
    }
    conclusions {
      id
    }
    cash {
      id
    }
  }
}
        `

        apolloProvider.defaultClient.query({query: GET_TH, variables: {id: i.id}}).then(response => {

          let data = response.data.history

          if (data===[])
            return

          let datas = data[0]

          //data.forEach(row => datas.push(this.generateTransactionText(row)))



          this.$set(this.cached,   i.id , datas)


          // console.log(data)
        });

        console.log("data")

      }

    },


    generateTransactionText(transactionHistories) {

      let {status, value, createdAt} = transactionHistories;
      createdAt = new Date(Date.parse(createdAt)).toLocaleString()

      let result = "";

      if (status === 'offer') {
        if (value && value.mode === 'auto') {
          result = `Оффер выдан автоматически в ${createdAt}`;
        } else if (value && value.mode === 'operator' && value.operator) {
          result = `Оператор ${value.operator} вручную выдал карту в ${createdAt}`;
        }

      } else if (status === 'canceled') {
        if (value) {
          if (value.requestor === 'client') {
            result = `Клиент отменил пополнение в ${createdAt}`;
          } else if (value.requestor === 'operator' && value.operator) {
            result = `Оператор ${value.operator} отменил пополнение в ${createdAt}`;
          } else if (value.requestor === 'timeout') {
            result = `Пополнение было отменено по таймауту в ${createdAt}`;
          }
        }


      } else if (status === 'change') {
        if (value && value.old && value.new) {
          result = `Клиент изменил сумму или банк: было ${value.old.amount}, стало ${value.new.amount} в ${createdAt}`;
        } else if (value && value.operator) {

          result = "Оператор ${value.operator} изменил сумму, было: ${value.old.amount}, стало ${value.new.amount} в ${createdAt}"
        }

      } else if (status === 'accepted') {
        if (value && value.operator && value.recovery) {
          result = `Оператор ${value.operator} восстановил пополнение в ${createdAt}`;
        } else if (value && value.operator) {
          result = `Оператор ${value.operator} одобрил пополнение в ${createdAt}`;
        } else {
          result = `Клиент принял оффер в ${createdAt}`;
        }

      } else if (status === 'proof') {
        if (value && value.name && value.mimetype) {
          result = `Клиент сообщил о переводе и приложил пруф: ${process.env.VUE_APP_FILES_BASE}/proof/${value.name}?type=${value.mimetype} в ${createdAt}`;
        } else {
          result = `Клиент сообщил о переводе в ${createdAt}`;
        }
      } else if (status === 'completed') {
        if (value && value.operator) {
          if (value.mode === 'direct') {
            result = `Оператор ${value.operator} сделал прямое пополнение в ${createdAt}`;
          } else if (value.mode === 'reclose') {
            result = `Оператор ${value.operator} перевел пополнение из отмены в успех в ${createdAt}`;
          } else {
            result = `Оператор ${value.operator} одобрил пополнение в ${createdAt}`;
          }
        } else {
          result = 'Событие "completed" без указания оператора';
        }
      }

      return result || 'Неизвестное событие';
    }
    ,

    formatPeriod(startISO, endISO) {
      const startTime = new Date(startISO);
      const endTime = new Date(endISO);

      const durationInMillis = endTime - startTime;
      const hours = Math.floor(durationInMillis / (1000 * 60 * 60));
      const minutes = Math.floor((durationInMillis % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((durationInMillis % (1000 * 60)) / 1000);

      let periodString = "";

      if (hours > 0) {
        periodString += `${hours} час, `;
      }
      if (minutes > 0 || hours > 0) {
        periodString += `${minutes} мин, `;
      }
      periodString += `${seconds} сек`;

      return periodString;
    }
    ,

    get$ByCountry(country) {

      switch (country) {
        case "ru" :
          return "₽"
        case "en" :
          return "$"
        case "tr" :
          return "₺"

        default:
          return "def$"
      }

    }
    ,

    userchanged(value) {
      this.user = value ? value.username : ""
    }
    ,

    bankchanged(value) {
      this.bank = value ? value.name : ""
    }
    ,

    currencychanged(value) {
      this.country = value.country
    }
    ,


    csv0() {
      const json2csv = require('json2csv').parse;

      var table = []

      this.transactions.forEach(
          value => table.push({
            id: value.id,
            status: value.status,
            updatedAt: new Date(Date.parse(value.updatedAt)).toLocaleString(),
            amountUsd: this.calcUsdAmount(value).toLocaleString('ru-RU', {style: 'decimal',}),
            amountRub: this.calcRubAmount(value).toLocaleString('ru-RU', {style: 'decimal',}),
            bank: value.bank ? value.bank.name : "",
            usdt: this.usdt0(value).toLocaleString('ru-RU', {style: 'decimal',}),
            cardN: value.card ? value.card.number : "",
            cardO: value.card ? value.card.owner : "",
            user: value.user ? value.user.name : "",
            uid: value.uid
          })
      )

      const opts = {
        delimiter: ';',
      };

// Преобразуем данные в CSV формат
      return json2csv(table, opts)
    }
    ,

    csv(win1251 = false) {

      const iconv = require('iconv-lite');

      let blob = {}

      if (win1251) {
        const c = iconv.encode(this.csv0(), 'win1251');
        blob = new Blob([c], {type: 'text/csv;charset=windows-1251;'});
      } else {
        const c = this.csv0()
        blob = new Blob([c], {type: 'text/csv;charset=utf-8;'});
      }

// Создаем объект типа Blob, который содержит данные в формате CSV

// Создаем ссылку на объект Blob
      const url = URL.createObjectURL(blob);
// Создаем элемент <a>, который будет использоваться для инициации скачивания
      const a = document.createElement('a');
      a.href = url;
// Указываем имя файла в атрибуте download элемента <a>

      var filterString = this.special4watch
      var downloadTime = new Date().toLocaleString()
      var user = this.username
      var filename = (user + ";" + downloadTime + ";" + filterString)
      a.download = filename + this.getChecksum(filename) + '.csv';

// Добавляем элемент <a> в DOM дерево
      document.body.appendChild(a);
// Инициируем скачивание
      a.click();
// Удаляем элемент <a> из DOM дерева
      document.body.removeChild(a);
    }
    ,

    getChecksum(str) {
      const digits = str.toUpperCase().split('').map(char => char.charCodeAt(0) - 64); // Преобразуем строку букв в массив цифр
      let sum = 0;

      for (let i = digits.length - 1; i >= 0; i--) {
        let digit = digits[i];

        if ((digits.length - i) % 2 === 0) { // Удваиваем каждую вторую цифру
          digit *= 2;
          if (digit >= 10) { // Заменяем удвоенную цифру на сумму цифр
            digit = digit.toString().split('').map(Number).reduce((acc, val) => acc + val, 0);
          }
        }

        sum += digit;
      }

      return (sum % 10 === 0) ? 0 : 10 - (sum % 10); // Вычисляем контрольную сумму


    }
    ,


    computeSumm() {

      let sumRub = 0;
      let sumUsd = 0;
      let sumUsdt = 0;

      this.transactions.forEach(
          t => {
            if (t.status === 'completed') {
              sumRub += this.calcRubAmount(t)
              sumUsd += this.calcUsdAmount0(t)
              sumUsdt += this.usdt0(t)
            }
          }
      );

      this.sumRub = sumRub
      this.sumUsd = sumUsd
      this.sumUsdt = sumUsdt
    }
    ,

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    }
    ,

    onPerodChanged(val) {
      this.from = val.from
      this.to = val.to
    }
    ,

    async recovery(item) {
      /* PATCH
        patch("/api/admin/transaction/:id/recovery
      */

      if (!window.confirm(`Восстановить ? \n ID: ${item.id};
        Сумма: ${item.offer.amount};
        Мерчант: ${item.user ? item.user.username : '--'};
        Банк: ${item.bank ? item.bank.name : '--'};
            `)) {
        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
      };

      let url = `${RestApiUrl}/${item.id}/recovery`
      fetcher(this.dosnackbar, url, requestOptions)

    }
    ,

    async repeat(item) {
      /* PATCH
      /api/admin/transaction/:id/callback
      */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
      };

      let url = `${RestApiUrl}/${item.id}/callback`
      fetcher(this.dosnackbar, url, requestOptions)

    }
    ,

    async patch(item) {
      /* PATCH
      /api/admin/transaction/:id/reclose
      */

      if (!window.confirm(`Перевести в Completed ? \n ID: ${item.id};
        Сумма: ${item.offer.amount};
        Мерчант: ${item.user ? item.user.username : '--'};
        Банк: ${item.bank ? item.bank.name : '--'};
            `)) {

        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,

      };

      let url = `${RestApiUrl}/${item.id}/reclose`

      fetcher(this.dosnackbar, url, requestOptions)


    }
    ,

    usdt(item) {


      if (item.status === 'canceled')
        return "--"

      //  USD * (100 - Комисиия)/100

      //Amount * (100 - offer_fee)/100

      if (item.currency === 'RUB')
        return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
      else
        return (item.amount * (100 - item.offer.fee) / 100) || 0


      //
    }
    ,

    usdt0(item) {


      if (item.status === 'canceled')
        return 0

      //  USD * (100 - Комисиия)/100

      //Amount * (100 - offer_fee)/100

      if (item.currency === 'RUB')
        return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
      else
        return (item.amount * (100 - item.offer.fee) / 100) || 0


      //
    }
    ,

    calcRubAmount(item) {
      if (item.currency === 'RUB')
        return item.amount
      else
        return item.offer.amount
    }
    ,

    calcUsdAmount(item) {
      if (item.currency === 'USD')
        return item.amount
      else
        return ''
    }
    ,

    calcUsdAmount0(item) {
      if (item.currency === 'USD')
        return item.amount
      else
        return 0
    }
    ,

    async amount(id, amount) {
      //Изменить сумму
      //app.patch("/api/admin/transaction/:id/amount"
      //req.body.amount - сумма в рублях (float)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({amount: amount})
      };

      let url = `${RestApiUrl}/${id}/amount`


      fetcher(this.dosnackbar, url, requestOptions)


    }
    ,

    async approve(item) {
      //Одобрение
      //app.put("/api/admin/transaction/:id"

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PUT',
        headers: headers,
        //body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`


      fetcher(this.dosnackbar, url, requestOptions)


    }
    ,

    async del(item) {
      //Удаление заявки
      // app.delete("/api/admin/transaction/:id"

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        //body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`


      fetcher(this.dosnackbar, url, requestOptions)


    }
    ,


    async save(item, mode) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)

    }
    ,


    fin(val, r = 2) {
      return val === '--' ? '--' : Number.parseFloat(val).toFixed(r);
    }
    ,
  },

}
</script>
