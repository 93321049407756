var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c("DatePeriodPicker", {
        attrs: { caption: "Период выборки", from: _vm.from, to: _vm.to },
        on: { changed: _vm.onPerodChanged },
      }),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _vm._m(1),
          _c(
            "v-form",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "ID",
                          outlined: "",
                          clearable: "",
                          type: "number",
                          max: "2147483647",
                          oninput: "validity.valid||(value='');",
                        },
                        model: {
                          value: _vm.id,
                          callback: function ($$v) {
                            _vm.id = $$v
                          },
                          expression: "id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Карта", outlined: "", clearable: "" },
                        model: {
                          value: _vm.card,
                          callback: function ($$v) {
                            _vm.card = $$v
                          },
                          expression: "card",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("CurrencySelect", {
                        on: { changed: _vm.currencychanged },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("BankCombobox3", {
                        attrs: { bank: _vm.bank },
                        on: {
                          "update:bank": function ($event) {
                            _vm.bank = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("ExchangeUserComboboxMerchant", {
                        attrs: { user: _vm.user },
                        on: {
                          "update:user": function ($event) {
                            _vm.user = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "", dense: "" },
                          model: {
                            value: _vm.radios,
                            callback: function ($$v) {
                              _vm.radios = $$v
                            },
                            expression: "radios",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: "Только успешные", value: "1" },
                          }),
                          _c("v-radio", {
                            attrs: { label: "Все", value: "2" },
                          }),
                          _c("v-radio", {
                            attrs: { label: "Только отмены", value: "3" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center pt-2" },
        [
          _c("v-pagination", {
            attrs: { length: _vm.pageCount, "total-visible": 15 },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-center ma-2" },
            [
              _c("span", { staticClass: "mr-1 mt-1" }, [
                _vm._v(" Показывать на странице: "),
              ]),
              _c("v-select", {
                staticStyle: { "max-width": "80px" },
                attrs: { dense: "", items: _vm.Pages, "return-object": "" },
                model: {
                  value: _vm.itemsPerPage,
                  callback: function ($$v) {
                    _vm.itemsPerPage = $$v
                  },
                  expression: "itemsPerPage",
                },
              }),
              _c("span", { staticClass: "ml-1 mt-1" }, [
                _vm._v(" строк из " + _vm._s(_vm.totalRows)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-2 ma-0", attrs: { height: "130" } },
                [
                  _vm._v(" Итого суммы по текущей странице:"),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.sumRub.toLocaleString("ru-RU", {
                          style: "currency",
                          currency: "RUB",
                        })
                      )
                  ),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.sumUsd.toLocaleString("ru-RU", {
                          style: "currency",
                          currency: "USD",
                        })
                      )
                  ),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.sumUsdt.toLocaleString("ru-RU", {
                          style: "currency",
                          currency: "USD",
                        })
                      ) +
                      "USDT "
                  ),
                  _c("br"),
                  _c("span", { staticClass: "font-weight-thin" }, [
                    _vm._v("Суммируется только completed по текущей странице."),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-2 ma-0", attrs: { height: "130" } },
                [
                  _c("span", [_vm._v(" Скачать CSV по текущей выборке:")]),
                  _c("br"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      on: {
                        click: function ($event) {
                          return _vm.csv(false)
                        },
                      },
                    },
                    [_vm._v("В кодировке UTF-8")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      on: {
                        click: function ($event) {
                          return _vm.csv(true)
                        },
                      },
                    },
                    [_vm._v("В кодировке Win-1251")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 mt-3",
            attrs: {
              "sort-by": "id",
              "sort-desc": "",
              "item-key": "id",
              "show-expand": "",
              "expanded.sync": "expanded",
              "hide-default-footer": "",
              "disable-pagination": "",
              dense: "",
              "multi-sort": "",
              items: _vm.transactions,
              headers: _vm.headers,
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            on: { "item-expanded": _vm.expevent },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    item.status === "created"
                      ? _c("v-chip", { attrs: { color: "primary" } }, [
                          _vm._v(" " + _vm._s(item.status)),
                        ])
                      : item.status === "canceled"
                      ? _c("v-chip", { attrs: { color: "gray" } }, [
                          _vm._v(" " + _vm._s(item.status)),
                        ])
                      : item.status === "completed"
                      ? _c("v-chip", { attrs: { color: "green" } }, [
                          _vm._v(" " + _vm._s(item.status)),
                        ])
                      : item.status === "offer"
                      ? _c("v-chip", { attrs: { color: "orange" } }, [
                          _vm._v(" " + _vm._s(item.status)),
                        ])
                      : item.status === "accepted"
                      ? _c("v-chip", { attrs: { color: "green" } }, [
                          _vm._v(" " + _vm._s(item.status)),
                        ])
                      : item.status === "proof"
                      ? _c("v-chip", { attrs: { color: "blue" } }, [
                          _vm._v(" " + _vm._s(item.status)),
                        ])
                      : _c("v-chip", { attrs: { color: "brown" } }, [
                          _vm._v(" " + _vm._s(item.status)),
                        ]),
                  ]
                },
              },
              {
                key: "item.updatedAt",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(Date.parse(item.updatedAt)).toLocaleString()
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.amountUsd",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.calcUsdAmount(item).toLocaleString("ru-RU"))
                    ),
                  ]
                },
              },
              {
                key: "item.amountRub",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.calcRubAmount(item).toLocaleString("ru-RU"))
                    ),
                  ]
                },
              },
              {
                key: "item.fee",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.offer.fee))]
                },
              },
              {
                key: "item.rate",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.offer.rate))]
                },
              },
              {
                key: "item.usdt",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .usdt(item)
                            .toLocaleString("ru-RU", {
                              style: "currency",
                              currency: "USD",
                            }) + "USDT"
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.currency",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.bank
                            ? _vm.get$ByCountry(item.bank.country)
                            : "--"
                        )
                    ),
                  ]
                },
              },
              {
                key: "item.bank",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.bank ? item.bank.name : "--")),
                  ]
                },
              },
              {
                key: "item.card",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.card ? item.card.number : "--")),
                  ]
                },
              },
              {
                key: "item.cardowner",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.card ? item.card.owner : "--")),
                  ]
                },
              },
              {
                key: "item.user",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.user ? item.user.username : "--")),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    item.status === "canceled"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "orange",
                            attrs: { dense: "", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.patch(item)
                              },
                            },
                          },
                          [_vm._v("в Completed ")]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary",
                        attrs: { dense: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.repeat(item)
                          },
                        },
                      },
                      [_vm._v("Повтор колбэк ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "brown",
                        attrs: { dense: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.recovery(item)
                          },
                        },
                      },
                      [_vm._v("Восстановить ")]
                    ),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c("td", { attrs: { colspan: headers.length - 2 } }, [
                      _vm.cached[item.id]
                        ? _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(`Курс: ${_vm.cached[item.id].course}`)
                              ),
                            ]),
                            _c("br"),
                            _c("span", [
                              _vm._v(
                                _vm._s(`ID операции: ${_vm.cached[item.id].id}`)
                              ),
                            ]),
                            _c("br"),
                          ])
                        : _vm._e(),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            `ИД чата: ${item.card ? item.card.chatId : "--"}`
                          )
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            `Время обработки: ${_vm.formatPeriod(
                              item.createdAt,
                              item.updatedAt
                            )}`
                          )
                        ),
                      ]),
                      _c("br"),
                      _c("span", [_vm._v("История:")]),
                      _c("br"),
                      _vm.cached2[item.id]
                        ? _c(
                            "div",
                            _vm._l(_vm.cached2[item.id], function (row) {
                              return _c("div", { key: row }, [
                                _c("span", [_vm._v(_vm._s(` ${row}`))]),
                                _c("br"),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center pt-2" },
        [
          _c("v-pagination", {
            attrs: { length: _vm.pageCount, "total-visible": 15 },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-center ma-2" },
            [
              _c("span", { staticClass: "mr-1 mt-1" }, [
                _vm._v(" Показывать на странице: "),
              ]),
              _c("v-select", {
                staticStyle: { "max-width": "80px" },
                attrs: { dense: "", items: _vm.Pages, "return-object": "" },
                model: {
                  value: _vm.itemsPerPage,
                  callback: function ($$v) {
                    _vm.itemsPerPage = $$v
                  },
                  expression: "itemsPerPage",
                },
              }),
              _c("span", { staticClass: "ml-1 mt-1" }, [
                _vm._v(" строк из " + _vm._s(_vm.totalRows)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Transactions mt-5" }, [
      _c("h3", [_vm._v("Обменник - Архив - транзакции ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Фильтр по столбцам")]),
      _vm._v(" - работает по вхождению подстроки. В ID - только цифры."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }